import React, { useEffect, useRef } from "react";

function VrtSessionDetail({ patientId, agora }) {
  const videoRef = useRef(null);
  const client = window.AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

  useEffect(() => {
    const initAgora = async () => {
      const { appid, channel_name, token } = agora[0];

      // Configura il client Agora per ricevere solo il flusso
      client.init(appid, () => console.log("AgoraRTC client initialized"), handleError);

      // Unisciti al canale senza pubblicare alcun flusso
      client.join(token, channel_name, null, (uid) => {
        console.log("User " + uid + " joined the channel as a viewer");

        // Sottoscrivi al flusso video remoto
        client.on("stream-added", function (evt) {
          client.subscribe(evt.stream, handleError);
        });

        client.on("stream-subscribed", function (evt) {
          const remoteStream = evt.stream;
          remoteStream.play("video-container");
        });
      }, handleError);
    };

    const handleError = (err) => {
      console.error("Agora error:", err);
    };

    initAgora();

    return () => {
      client.leave(() => console.log("Client left the channel"), handleError);
    };
  }, [agora]);

  return (
    <div>
      <h2>Dettaglio Sessione</h2>
      <p><strong>ID Paziente:</strong> {patientId}</p>
      <div id="video-container" ref={videoRef} style={{ width: "100%", height: "500px", backgroundColor: "#000" }}>
        {/* Il video remoto verrà visualizzato qui */}
      </div>
    </div>
  );
}

export default VrtSessionDetail;
