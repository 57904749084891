import React, { useState,useEffect } from 'react';
import style from "./VrtSession.module.sass";
const SelectButtonEducational = ({ levelName, scenery, titleEducationalLevel,presetEducational, onSelect,onSelectValuesChange }) => {  
  const [selectedId, setSelectedId] = useState(null);
  const [selectValues, setSelectValues] = useState({
    code: '',
    time_day: '',
    weather: '',
    sounds: '',
    trafficLights: '',
    crosswalks: '',
    cars: '',
    pedestrians: ''
  });

  const [options, setOptions] = useState({
    time_day: [],
    weather: [],
    sounds: [],
    trafficLights: [],
    crosswalks: [],
    cars: [],
    pedestrians: []
  });

  useEffect(() => {
    if (presetEducational && presetEducational.length > 0) {
      const firstId = presetEducational[0].id;
      setSelectedId(firstId);
      onSelect(firstId);
      updateSelectFields(firstId);
    }
  }, [presetEducational, onSelect]);

  const handleButtonClick = (e, id) => {
    e.preventDefault();
    setSelectedId(id);
    onSelect(id);
    // Esegui l'azione desiderata quando viene cliccato un bottone
    console.log('Selected ID:', id);
    // Puoi anche inviare una richiesta al server o aggiornare lo stato dell'applicazione
    updateSelectFields(id);
  };
  const updateSelectFields = (id) => {
    const selectedPreset = presetEducational.find(preset => preset.id === id);
    if (selectedPreset) {
      const selectedCode = selectedPreset.code || '';
      const newSelectValues = {
        code: selectedCode,
        time_day: selectedPreset.time_day || '',
        weather: selectedPreset.weather || '',
        sounds: selectedPreset.sounds || '',
        trafficLights: scenery.find(s => s.code === selectedCode)?.trafficLightsOptions?.[0] || '',
        crosswalks: scenery.find(s => s.code === selectedCode)?.crosswalksOptions?.[0] || '',
        cars: scenery.find(s => s.code === selectedCode)?.carsOptions?.[0] || '',
        pedestrians: scenery.find(s => s.code === selectedCode)?.pedestriansOptions?.[0] || ''
  
      };
      setSelectValues(newSelectValues);
      onSelectValuesChange(newSelectValues);
      updateOptions(selectedCode);
    }
  };


  
  const updateOptions = (code) => {
    // Cerca il codice selezionato all'interno del campo scenery
    const sceneryOptions = scenery.find(s => s.code === code);
    console.log(sceneryOptions)
    if (sceneryOptions) {
      setOptions({
        time_day: sceneryOptions.time_dayOptions || [],
        weather: sceneryOptions.weatherOptions || [],
        sounds: sceneryOptions.soundsOptions || [],
        trafficLights: sceneryOptions.trafficLightsOptions || [],
        crosswalks: sceneryOptions.crosswalksOptions || [],
        cars: sceneryOptions.carsOptions || [],
        pedestrians: sceneryOptions.pedestriansOptions || []
      });

    // Reset dei campi che non sono disponibili nel nuovo scenario

    setSelectValues(prevValues => ({
     ...prevValues,
     time_day: sceneryOptions.time_dayOptions?.length>0 ? prevValues.time_day : '',
     weather: sceneryOptions.weatherOptions?.length>0 ? prevValues.weather : '',
     sounds: sceneryOptions.soundsOptions?.length>0 ? prevValues.sounds : '',
     trafficLights: sceneryOptions.trafficLightsOptions?.length>0 ? prevValues.trafficLights : '',
     crosswalks: sceneryOptions.crosswalksOptions?.length>0 ? prevValues.crosswalks : '',
     cars: sceneryOptions.carsOptions?.length>0 ? prevValues.cars : '',
     pedestrians: sceneryOptions.pedestriansOptions?.length>0 ? prevValues.pedestrians : ''
    }));
    }
  };


  const updateOptionCode = (code) => {
    const sceneryOptions = scenery.find(s => s.code === code);
    if (sceneryOptions) {
      const newOptions = {
        time_day: sceneryOptions.time_dayOptions || [],
        weather: sceneryOptions.weatherOptions || [],
        sounds: sceneryOptions.soundsOptions || [],
        trafficLights: sceneryOptions.trafficLightsOptions || [],
        crosswalks: sceneryOptions.crosswalksOptions || [],
        cars: sceneryOptions.carsOptions || [],
        pedestrians: sceneryOptions.pedestriansOptions || []
      };

      setOptions(newOptions);

      // Imposta automaticamente il primo valore di ciascuna opzione nello stato
      setSelectValues(prevValues => (
        {
        ...prevValues,
        time_day: newOptions.time_day.length > 0 ? newOptions.time_day[0] : '',
        weather: newOptions.weather.length > 0 ? newOptions.weather[0] : '',
        sounds: newOptions.sounds.length > 0 ? newOptions.sounds[0] : '',
        trafficLights: newOptions.trafficLights.length > 0 ? newOptions.trafficLights[0] : '',
        crosswalks: newOptions.crosswalks.length > 0 ? newOptions.crosswalks[0] : '',
        cars: newOptions.cars.length > 0 ? newOptions.cars[0] : '',
        pedestrians: newOptions.pedestrians.length > 0 ? newOptions.pedestrians[0] : ''
      }));

      // Aggiorna i valori selezionati con i nuovi valori
      onSelectValuesChange({
        code,
        time_day: newOptions.time_day.length > 0 ? newOptions.time_day[0] : '',
        weather: newOptions.weather.length > 0 ? newOptions.weather[0] : '',
        sounds: newOptions.sounds.length > 0 ? newOptions.sounds[0] : '',
        trafficLights: newOptions.trafficLights.length > 0 ? newOptions.trafficLights[0] : '',
        crosswalks: newOptions.crosswalks.length > 0 ? newOptions.crosswalks[0] : '',
        cars: newOptions.cars.length > 0 ? newOptions.cars[0] : '',
        pedestrians: newOptions.pedestrians.length > 0 ? newOptions.pedestrians[0] : ''
      });
    }
  };

  const handleSelectChange = (e, selectName) => {
    const value = e.target.value;
    const newSelectValues = {
      ...selectValues,
      [selectName]: value
    };
    
    setSelectValues(newSelectValues);
    onSelectValuesChange(newSelectValues);

    if (selectName === 'code') {
      updateOptionCode(value);
    }
  };

  return (
    <div className={style.formField}>
    <div className={`${style.left}  ${style.labelField}`}> {levelName}</div>
    <div className={style.right}> 
      {presetEducational.map(preset => (
        <button
          key={preset.id}
          className={`${style.select_button} ${selectedId === preset.id ? style.selected : ''}`} // Puoi usare la stessa classe per lo stile
          onClick={(e) => handleButtonClick(e, preset.id)}
          style={{ marginBottom: "12px" }}
        >
          {preset.id}
        </button>
      ))}
    </div>
    <div className={style.voidRight} />
    <div className={`${style.left}  ${style.labelField}`}> Scenario </div>
    <div className={style.right}>       
          {/* Select Boxes */}
       
          <select value={selectValues.code} onChange={(e) => handleSelectChange(e, 'code')}>
          
            {scenery.map(s => (
              <option key={s.code} value={s.code}>
                {s.code}
              </option>
            ))}
          </select>
    </div>
    <div className={style.voidRight} />

    <div className={`${style.left}  ${style.labelField}`}> {options.time_day.length > 0 && 'Condizioni di luce'}</div>
    <div className={style.right}>            
          {options.time_day.length > 0 && (
            <select value={selectValues.time_day} onChange={(e) => handleSelectChange(e, 'time_day')}>
              {options.time_day.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
    </div>
    <div className={style.voidRight} />

    <div className={`${style.left}  ${style.labelField}`}> {options.weather.length > 0 && 'Clima'}</div>
    <div className={style.right}>     
          {options.weather.length > 0 && (
            <select value={selectValues.weather} onChange={(e) => handleSelectChange(e, 'weather')}>
            
              {options.weather.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
    </div>
    <div className={style.voidRight} />

    <div className={`${style.left}  ${style.labelField}`}> {options.sounds.length > 0 && 'Suoni ambientali e musica'}</div>
    <div className={style.right}>     
          {options.sounds.length > 0 && (
            <select value={selectValues.sounds} onChange={(e) => handleSelectChange(e, 'sounds')}>
              {options.sounds.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
    </div>
    <div className={style.voidRight} />

    <div className={`${style.left}  ${style.labelField}`}> {options.trafficLights.length > 0 && 'Semafori e controllo colore'}</div>
    <div className={style.right}>     
          {options.trafficLights.length > 0 && (
            <select value={selectValues.trafficLights} onChange={(e) => handleSelectChange(e, 'trafficLights')}>
              {options.trafficLights.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
    </div>
    <div className={style.voidRight} />  

    <div className={`${style.left}  ${style.labelField}`}> {options.crosswalks.length > 0 && 'Strisce pedonali'}</div>
    <div className={style.right}>     
          {options.crosswalks.length > 0 && (
            <select value={selectValues.crosswalks} onChange={(e) => handleSelectChange(e, 'crosswalks')}>
              {options.crosswalks.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
    </div>
    <div className={style.voidRight} />  

    <div className={`${style.left}  ${style.labelField}`}> {options.cars.length > 0 && 'Preferenze per i veicoli'}</div>
    <div className={style.right}>     
          {options.cars.length > 0 && (
            <select value={selectValues.cars} onChange={(e) => handleSelectChange(e, 'cars')}>
              {options.cars.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
    </div>
    <div className={style.voidRight} />  

    <div className={`${style.left}  ${style.labelField}`}> {options.pedestrians.length > 0 && 'Altri pedoni'}</div>
    <div className={style.right}>     
          {options.pedestrians.length > 0 && (
            <select value={selectValues.pedestrians} onChange={(e) => handleSelectChange(e, 'pedestrians')}>
              {options.pedestrians.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
    </div>
    <div className={style.voidRight} />  


    </div>

    
  );
};

export default SelectButtonEducational;
