import React, { useState,useEffect } from 'react';
import style from "./VrtSession.module.sass";
import RelaxScenarioSelectBoxes from './SelectInteraction';
const SelectButtonRelax = ({ presetRelax,scenery,titlePreset, onSelect, onSelectValuesChange}) => {  
  const [selectedId, setSelectedId] = useState(null);
  const [selectValues, setSelectValues] = useState({
    code: '',
    time_day: '',
    weather: '',
    sounds: '',
    interactions_colors: '',
    interactions_objects: '',
    interactions_video_feedbacks: '',
    interactions_audio_feedbacks: ''
  });

  const [options, setOptions] = useState({
    time_day: [],
    weather: [],
    sounds: [],
    interactions_colors: [],
    interactions_objects: [],
    interactions_video_feedbacks: [],
    interactions_audio_feedbacks: []
  });

  useEffect(() => {
    if (presetRelax && presetRelax.length > 0) {
      const firstId = presetRelax[0].id;
      setSelectedId(firstId);
      onSelect(firstId);
      updateSelectFields(firstId);
    }
  }, [presetRelax, onSelect]);

  const handleButtonClick = (e, id) => {
    e.preventDefault();
    setSelectedId(id);
    onSelect(id);
    // Esegui l'azione desiderata quando viene cliccato un bottone
    console.log('Selected ID:', id);
    // Puoi anche inviare una richiesta al server o aggiornare lo stato dell'applicazione
    updateSelectFields(id);
  };

  const updateSelectFields = (id) => {
    const selectedPreset = presetRelax.find(preset => preset.id === id);
    if (selectedPreset) {
      const selectedCode = selectedPreset.code || '';
      const newSelectValues = {
        code: selectedCode,
        time_day: selectedPreset.time_day || '',
        weather: selectedPreset.weather || '',
        sounds: selectedPreset.sounds || '',
        interactions_colors: selectedPreset.interactions_colors || '',
        interactions_objects: selectedPreset.interactions_objects || '',
        interactions_video_feedbacks: selectedPreset.interactions_video_feedbacks || '',
        interactions_audio_feedbacks: selectedPreset.interactions_audio_feedbacks || ''
      };
      
      setSelectValues(newSelectValues);
      onSelectValuesChange(newSelectValues);
      updateOptions(selectedCode);
    }
  };

 

  const updateOptions = (code) => {
    const sceneryOptions = scenery.find(s => s.code === code);
    if (sceneryOptions) {

      setOptions({    
        time_day: sceneryOptions.time_dayOptions || [],
        weather: sceneryOptions.weatherOptions || [],
        sounds: sceneryOptions.soundsOptions || [],
        interactions_colors: sceneryOptions.interactions_colorsOptions || [],
        interactions_objects: sceneryOptions.interactions_objectsOptions || [],
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions || [],
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions || []
      });
      // Reset dei campi che non sono disponibili nel nuovo scenario
      setSelectValues(prevValues => ({
        ...prevValues,
        time_day: sceneryOptions.time_dayOptions?.length>0 ? prevValues.time_day : '',
        weather: sceneryOptions.weatherOptions?.length>0 ? prevValues.weather : '',
        sounds: sceneryOptions.soundsOptions?.length>0 ? prevValues.sounds : '',
        interactions_colors: sceneryOptions.interactions_colorsOptions ? prevValues.interactions_colors : 'seleziona interazione',
        interactions_objects: sceneryOptions.interactions_objectsOptions ? prevValues.interactions_objects : 'seleziona interazione',
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions ? prevValues.interactions_video_feedbacks : 'seleziona interazione',
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions ? prevValues.interactions_audio_feedbacks : 'seleziona interazione'
    }));


    }
  };

  const updateOptionCode = (code) => {
    const sceneryOptions = scenery.find(s => s.code === code);
    if (sceneryOptions) {

      const newOptions = {    
        time_day: sceneryOptions.time_dayOptions || [],
        weather: sceneryOptions.weatherOptions || [],
        sounds: sceneryOptions.soundsOptions || [],
        interactions_colors: sceneryOptions.interactions_colorsOptions || [],
        interactions_objects: sceneryOptions.interactions_objectsOptions || [],
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions || [],
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions || []
      };

      setOptions(newOptions);
      // Reset dei campi che non sono disponibili nel nuovo scenario
      setSelectValues(prevValues => ({
        ...prevValues,
        time_day: sceneryOptions.time_dayOptions?.length>0 ?  newOptions.time_day[0]: '',
        weather: sceneryOptions.weatherOptions?.length>0 ?  newOptions.weather[0] : '',
        sounds: sceneryOptions.soundsOptions?.length>0 ? newOptions.sounds[0] : '',
        interactions_colors: sceneryOptions.interactions_colorsOptions ? prevValues.interactions_colors : 'seleziona interazione',
        interactions_objects: sceneryOptions.interactions_objectsOptions ? prevValues.interactions_objects : 'seleziona interazione',
        interactions_video_feedbacks: sceneryOptions.interactions_video_feedbacksOptions ? prevValues.interactions_video_feedbacks : 'seleziona interazione',
        interactions_audio_feedbacks: sceneryOptions.interactions_audio_feedbacksOptions ? prevValues.interactions_audio_feedbacks : 'seleziona interazione'
    }));

    // Aggiorna i valori selezionati con i nuovi valori
    onSelectValuesChange({
      code,
      time_day: newOptions.time_day.length > 0 ? newOptions.time_day[0] : '',
      weather: newOptions.weather.length > 0 ? newOptions.weather[0] : '',
      sounds: newOptions.sounds.length > 0 ? newOptions.sounds[0] : ''
    });
    }
  };

  const handleSelectChange = (e, selectName) => {
    const value = e.target.value;
    let newSelectValues = {
      ...selectValues,
      [selectName]: value
    };
    
    if (['interactions_colors', 'interactions_objects', 'interactions_video_feedbacks', 'interactions_audio_feedbacks'].includes(selectName)) {
      newSelectValues = {
          ...newSelectValues,
          interactions_colors: selectName === 'interactions_colors' ? value : '',
          interactions_objects: selectName === 'interactions_objects' ? value : '',
          interactions_video_feedbacks: selectName === 'interactions_video_feedbacks' ? value : '',
          interactions_audio_feedbacks: selectName === 'interactions_audio_feedbacks' ? value : ''
      };
  }

    setSelectValues(newSelectValues);
    onSelectValuesChange(newSelectValues);

    if (selectName === 'code') {
      updateOptionCode(value);
    }
  };

  return (
    <div className={style.formField}>
      <div className={`${style.left}  ${style.labelField}`}> {titlePreset}</div>
      <div className={style.right}> 
        {presetRelax.map(preset => (
          <button
            key={preset.id}
            className={`${style.select_button} ${selectedId === preset.id ? style.selected : ''}`} // Puoi usare la stessa classe per lo stile
            onClick={(e) => handleButtonClick(e, preset.id)}
            style={{ marginBottom: "12px" }}
          >
            {preset.id}
          </button>
        ))}
                  
      </div>
      <div className={style.voidRight} />
      <div className={`${style.left}  ${style.labelField}`}> Scenario </div>
      <div className={style.right}> 
        <select value={selectValues.code} onChange={(e) => handleSelectChange(e, 'code')}>
          {scenery.map(s => (
            <option key={s.code} value={s.code}>
              {s.code}
            </option>
          ))}
        </select>
      </div>      
      <div className={style.voidRight} />
      <RelaxScenarioSelectBoxes 
        options={options} 
        selectValues={selectValues} 
        handleSelectChange={handleSelectChange} 
      />
    </div>


  
  );
};

export default SelectButtonRelax;
